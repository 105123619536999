import React from 'react';
// import { Route, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/materialdesignicons.min.css";


import LandingPage from './pages/landingPage';

function App() {
  // useEffect(() => {
  //   document.getElementById('theme-opt').href = './css/style.scss'
  // }, [])
  return (
    <>
      <LandingPage/>
    </>
  );
}

export default App;
