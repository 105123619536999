import client1 from "../assets/images/client/01.jpg"
import client2 from "../assets/images/client/02.jpg"
import client3 from "../assets/images/client/03.jpg"
import client4 from "../assets/images/client/04.jpg"
import client5 from "../assets/images/client/05.jpg"
import client6 from "../assets/images/client/06.jpg"
import client7 from "../assets/images/client/07.jpg"
import client8 from "../assets/images/client/08.jpg"

import brand1 from "../assets/images/brands/3.png"
import brand2 from "../assets/images/brands/5.png"
import brand3 from "../assets/images/brands/6.png"
import brand4 from "../assets/images/brands/8.png"
import brand5 from "../assets/images/brands/19.png"
import brand6 from "../assets/images/brands/20.png"

import team1 from "../assets/images/client/09.jpg"
import team2 from "../assets/images/client/10.jpg"
import team3 from "../assets/images/client/11.jpg"
import team4 from "../assets/images/client/12.jpg"

import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"
import blog9 from "../assets/images/blog/9.jpg"

import portfolio1 from "../assets/images/portfolio/1.jpg"
import portfolio2 from "../assets/images/portfolio/2.jpg"
import portfolio3 from "../assets/images/portfolio/3.jpg"
import portfolio4 from "../assets/images/portfolio/4.jpg"
import portfolio5 from "../assets/images/portfolio/5.jpg"
import portfolio6 from "../assets/images/portfolio/6.jpg"
import portfolio7 from "../assets/images/portfolio/7.jpg"
import portfolio8 from "../assets/images/portfolio/8.jpg"
import portfolio9 from "../assets/images/portfolio/9.jpg"
import portfolio10 from "../assets/images/portfolio/10.jpg"
import portfolio11 from "../assets/images/portfolio/11.jpg"
import portfolio12 from "../assets/images/portfolio/12.jpg"
import portfolio13 from "../assets/images/portfolio/13.jpg"
import portfolio14 from "../assets/images/portfolio/14.jpg"
import portfolio15 from "../assets/images/portfolio/15.jpg"
import portfolio16 from "../assets/images/portfolio/16.jpg"

import food1 from "../assets/images/food/1.jpg"
import food2 from "../assets/images/food/2.jpg"
import food3 from "../assets/images/food/3.jpg"
import food4 from "../assets/images/food/4.jpg"
import food5 from "../assets/images/food/5.jpg"
import food6 from "../assets/images/food/6.jpg"
import food7 from "../assets/images/food/7.jpg"
import food8 from "../assets/images/food/8.jpg"
import food9 from "../assets/images/food/9.jpg"
import food10 from "../assets/images/food/10.jpg"
import food11 from "../assets/images/food/11.jpg"
import food12 from "../assets/images/food/12.jpg"
import food13 from "../assets/images/food/13.jpg"
import food14 from "../assets/images/food/14.jpg"
import food15 from "../assets/images/food/15.jpg"

import faceBook from "../assets/images/brands/facebook-logo-2019.png"
import google from "../assets/images/brands/google-logo.png"
import linkedin from "../assets/images/brands/linkedin.png"
import skype from "../assets/images/brands/skype.png"
import spotify from "../assets/images/brands/spotify.png"
import telegram from "../assets/images/brands/telegram.png"
import circle from "../assets/images/brands/circle-logo.png"
import lenovo from "../assets/images/brands/lenovo-logo.png"
import shreeLogo from "../assets/images/brands/shree-logo.png"
import snapchat from "../assets/images/brands/snapchat.png"
import android from "../assets/images/brands/android.png"


import {HiOutlinePresentationChartLine} from "react-icons/hi";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";
import {AiOutlineMoneyCollect, AiOutlineDollarCircle} from "react-icons/ai";
import {RiFileList3Line,RiPresentationLine} from "react-icons/ri";
export const featuresData = [
    {
        id:1,
        icon:HiOutlinePresentationChartLine,
        title:"Digital Marketing",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:2,
        icon:LiaFileInvoiceDollarSolid,
        title:"Investing",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:3,
        icon:AiOutlineMoneyCollect,
        title:"Mortgage Advisor",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:4,
        icon:RiPresentationLine,
        title:"Real Estate",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:5,
        icon:AiOutlineDollarCircle,
        title:"Payroll & Accounting",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:6,
        icon:RiFileList3Line,
        title:"Branch Registration",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
]
export const accordionData =[
    {
        id:0,
        title:"How much control do I have as a host?",
        desc:"We provide the matching algorithm, but everything else is up to you. Bring your own location, guests, and vibe. We’ve seen hosts throw dinner parties, apartment hangs, and cocktail hours—but some are going bigger: we’re seeing art gallery openings, boat parties, and huge summer picnics. Host whatever you’re most excited about!"
    },
    {
        id:1,
        title:"When should I have my guests complete the questionnaire?",
        desc:"Anytime you want! As host, you can architect the evening any way you want. We’ve had parties where guests complete the questionnaire all at the same time during the beginning of the event, and we’ve had parties where guests all complete the questionnaire beforehand—it’s entirely up to you."
    },
    {
        id:2,
        title:"When should I release matches?",
        desc:"Anytime you want! Again—as host, you can architect the evening any way you want. We think it’s most fun to let anticipation build throughout the evening, so we recommend releasing matches towards the end of the night."
    },
    {
        id:3,
        title:"What happens if I have an odd number of guests?",
        desc:"Right now, if there’s an odd number of guests, someone unlucky will go unmatched. It’s important to continue confirming your guest list as the event date approaches, and to invite an even number of guests. If you’re throwing a ticketed event, we’ve found that people who pay for tickets are much more likely to come. It’s not all about the end result—even when there’s an odd person unmatched, we find guests still have a great time answering questions and going through the shared experience with their friends."
    },
    {
        id:4,
        title:"How do I make sure the gender balance works out ok?",
        desc:"In general, if you’re hosting a romantic event, any surplus of men or women will receive platonic friend-matches among themselves, instead of romantic matches. To ensure balance, one good strategy is to request that every guest of yours brings a friend of the gender they’re interested in—this ensures your matching pool is always balanced. We’ve also seen hosts ask interested attendees about things like age and sexual orientation beforehand, in order to ensure everyone will get a match."
    },
    {
        id:5,
        title:"Are Matchbox events queer-friendly?",
        desc:"Yes! For romantic matching events, the algorithm takes into account (1) your gender identity, and (2) the gender identity/identities you’d like to match with. For platonic events, questions about gender and sexual orientation are omitted."
    },
    {
        id:6,
        title:"How do I curate my guest list?",
        desc:"Your guest list is entirely up to you! When we first started, we began by just inviting friends and friends-of-friends—that’s enough for a great party! More ambitious hosts often organize a waitlist, asking prospective guests for their social media handles, plus a question or two to get a sense of who they are."
    },
    {
        id:7,
        title:"Can I charge for tickets?",
        desc:"Premium (and higher)-tier events include a license to charge guests for tickets to Matchbox events."
    },
    {
        id:8,
        title:"How do I invite guests?",
        desc:"The invite itself is absolutely a piece of the experience as a whole! Remember to make it fun, exciting, and thoughtful. We’ve found that Partiful has been really helpful to manage our guest lists, but if you plan to charge for tickets, a platform with a built-in way to collect payments (like Eventbrite) might suit you better. We typically communicate with our guests via email, although we’ve seen hosts communicate with their guests via text messages, Instagram DMs, etc. as well.."
    },
    {
        id:9,
        title:"How can I make sure my guests have paid?",
        desc:"For smaller events that are relatively tame to manage (think 30-person apartment party), Venmo requests and a bit of diligence can work great. For larger ticketed events, platforms like Eventbrite, where guests can directly purchase a ticket, are your best bet."
    },
    {
        id:10,
        title:"Has there ever been a guest who wasn’t satisfied with their match? What happens?",
        desc:"Yes, this happens! Overall, matches are incredible—people make new friends, find people to date, and sometimes even match with exes. But while matches are algorithmically vetted—and they’re always good on paper—sometimes you won’t feel the spark with your match. That’s okay! Our guests enjoy going through the experience—answering the questions, and talking about it with their friends—regardless of how good their match ends up being."
    }

]
export const accordionData2 =[
    {
        id:0,
        title:"How does it work ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:1,
        title:"Do I need a designer to use Fronter ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:"What do I need to do to start selling ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:3,
        title:"Does CMR offer relocation assistance?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:4,
        title:"How is management perceived at CMR?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:5,
        title:"How is the work/life balance at CMR?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
]

export const clientsData = [
    {
        image:client1,
        name:"Young Bonetti",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
    {
        image:client2,
        name:"Ofelia Bell",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
    {
        image:client3,
        name:"Barbara Carpenter",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
    {
        image:client4,
        name:"Jesus Day",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
    {
        image:client5,
        name:"Jennifer Smith",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
    {
        image:client6,
        name:"Michael Wilkes",
        title:"Product Manager",
        desc:"It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text."
    },
]

export const partnersImg = [brand1,brand2,brand3,brand4,brand5,brand6]

export const teamData = [
    {
        image:team1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        image:team2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team3,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team4,
        name:"Aliana Rosy",
        title:"Designer"
    },
]
export const teamTwoData = [
    {
        id:1,
        image:client1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        id:2,
        image:client2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        id:3,
        image:client3,
        name:"Micheal Carlo",
        title:"Designer"
    },
    {
        id:4,
        image:client4,
        name:"Sofia Razaq",
        title:"Designer"
    },
    {
        id:5,
        image:client5,
        name:"Jack John",
        title:"Designer"
    },
    {
        id:6,
        image:client6,
        name:"Krista John",
        title:"Designer"
    },
    {
        id:7,
        image:client7,
        name:"Roger Jackson",
        title:"Designer"
    },
    {
        id:8,
        image:client8,
        name:"Johnny English",
        title:"Designer"
    },
]

export const blogData = [
    {
        id:1,
        image:blog1,
        title:"Building Your Corporate Identity from Fronter",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th Sep 2023"
    },
    {
        id:2,
        image:blog2,
        title:"The Dark Side of Overnight Success",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"29th Nov 2023"
    },
    {
        id:3,
        image:blog3,
        title:"The Right Hand of Business IT World",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"29th Dec 2023"
    },
    {
        id:4,
        image:blog4,
        title:"How to Create Your Own Viral Moments",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th March 2023"
    },
    {
        id:5,
        image:blog5,
        title:"How to Write a Business Plan For Any Business",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"6th May 2023"
    },
    {
        id:6,
        image:blog6,
        title:"Seeing the Customer Journey More Clearly",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"19th June 2023"
    },
    {
        id:7,
        image:blog7,
        title:"The Signs of a Highly Giftable Product",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"20th June 2023"
    },
    {
        id:8,
        image:blog8,
        title:"Defining Your Business Target Audience",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"31st Aug 2023"
    },
    {
        id:9,
        image:blog9,
        title:"Running Out of Time & Ideas? Visit Our Blog",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"1st Sep 2023"
    },
]

export const portfolioData = [
    {
        id:0,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:1,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:2,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:3,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
    {
        id:4,
        image: portfolio5,
        name:'Mockup box with paints',
        title:'Photography',
        category: 'branding',
    },
    {
        id:5,
        image: portfolio6,
        name:'Coffee cup',
        title:'Cups',
        category: 'photography',
    },
    {
        id:6,
        image: portfolio7,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:7,
        image: portfolio8,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:8,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:9,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:10,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:11,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
]
export const masonaryPortfolio = [
    {
        id:0,
        image:portfolio10,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:1,
        image:portfolio2,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:2,
        image:portfolio11,
        title:"Abstract images",
        name:"Abstract",
        category:"photography"
    },
    {
        id:3,
        image:portfolio13,
        title:"Company V-card",
        name:"V-card",
        category:"branding"
    },
    {
        id:4,
        image:portfolio6,
        title:"Mockup box with paints",
        name:"Branding",
        category:"Photography"
    },
    {
        id:5,
        image:portfolio7,
        title:"Coffee cup",
        name:"Cups",
        category:"designing"
    },
    {
        id:6,
        image:portfolio14,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:7,
        image:portfolio12,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:8,
        image:portfolio8,
        title:"Pen and article",
        name:"Article",
        category:"development"
    },
    {
        id:9,
        image:portfolio9,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:10,
        image:portfolio1,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:11,
        image:portfolio15,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:12,
        image:portfolio16,
        title:"Yellow bg with Books",
        name:"Branding",
        category:"Books"
    },

]
export const masonaryImage = [portfolio10,portfolio2,portfolio11,portfolio13,portfolio6,portfolio7,portfolio14,portfolio12,portfolio8,portfolio9,portfolio1,portfolio15]

export const portfolioImage = [portfolio1,portfolio2, portfolio3, portfolio4,portfolio5,portfolio6,portfolio7,portfolio8,portfolio1,portfolio2,portfolio3,portfolio4]

export const foodData = [
    {
        id:0,
        image:food1,
        title:"Black bean dip",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:1,
        image:food2,
        title:"Onion Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:2,
        image:food3,
        title:"Chicken Breast",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:3,
        image:food4,
        title:"Veg Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:4,
        image:food5,
        title:"Cordon Bleu",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:5,
        image:food6,
        title:"Boerewors",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:6,
        image:food7,
        title:"Tarte Tatin",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:7,
        image:food8,
        title:"Green Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:8,
        image:food9,
        title:"Special Coffee",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:9,
        image:food10,
        title:"Lemon Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:10,
        image:food11,
        title:"Pancakes",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:11,
        image:food12,
        title:"American Item",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:12,
        image:food13,
        title:"Country side pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:13,
        image:food14,
        title:"Chilly garlic potato",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:14,
        image:food15,
        title:"Brownie with vanilla",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
]
 
export const foodImg = [food1,food2,food3,food4,food5,food5,food6,food7,food8,food9,food10,food11,food12,food13,food14,food15]

export const jobData = [
    {
        id:1,
        title:"Software Engineering",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:faceBook,
        name:'Facebook Ltd.',
        location:'Australia',
        tag:'New'
    },
    {
        id:2,
        title:"Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:google,
        name:'Google Ltd.',
        location:'America'
    },
    {
        id:3,
        title:"UX/UI Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:linkedin,
        name:'Linkedin Ltd.',
        location:'Canada'
    },
    {
        id:4,
        title:"Human Resource(HR)",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:skype,
        name:'Skype Ltd.',
        location:'UK'
    },
    {
        id:5,
        title:"Web Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:spotify,
        name:'Spotify Ltd.',
        location:'China'
    },
    {
        id:6,
        title:"Graphic Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:telegram,
        name:'Telegram Ltd.',
        location:'India'
    },
    {
        id:7,
        title:"Senior Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:circle,
        name:'Circle CI Ltd.',
        location:'Australia'
    },
    {
        id:8,
        title:"Front-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:lenovo,
        name:'Lenovo Ltd.',
        location:'America'
    },
    {
        id:9,
        title:"Back-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'Canada'
    },
    {
        id:10,
        title:"Data Entry",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:snapchat,
        name:'Snapchat Ltd.',
        location:'UK'
    },
    {
        id:11,
        title:"Android Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:android,
        name:'Android Ltd.',
        location:'China'
    },
    {
        id:12,
        title:"Sketch Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'India'
    },
]